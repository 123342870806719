var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qualification"},[_c('el-form',{ref:"userForm",attrs:{"model":_vm.userForm,"label-width":"130px","label-position":"rigth"}},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card-heard"},[_vm._v("主申请人学历")]),_vm._l((_vm.userForm.educationList),function(item,index){return _c('div',{key:index,staticClass:"sister-box"},[(index != 0)?_c('div',{staticClass:"sister-heard"},[_c('div'),_c('div',{staticClass:"del",on:{"click":function($event){return _vm.educaDel(index)}}},[_vm._v("删除")])]):_vm._e(),_c('el-row',{staticClass:"block-top"},[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"one-input"},[_c('el-tooltip',{attrs:{"content":"国外大学请填写学位证上的英文名称","placement":"top"}},[_c('img',{staticClass:"sigh-img",staticStyle:{"left":"15px","bottom":"28px"},attrs:{"src":require("@/assets/img/hover.png"),"alt":""}})]),_c('el-form-item',{attrs:{"label":"颁授机构：","prop":'educationList.' + index + '.college',"rules":{
                                required: true, message: '颁授机构不能为空', trigger: 'change'
                            }}},[_c('el-autocomplete',{staticClass:"full-width",attrs:{"fetch-suggestions":_vm.querySearchAsync,"placeholder":"颁授机构"},model:{value:(item.college),callback:function ($$v) {_vm.$set(item, "college", $$v)},expression:"item.college"}})],1)],1),_c('div',[_c('el-form-item',{attrs:{"label":"授课类型：","prop":'educationList.' + index + '.type',"rules":{
                                required: true, message: '授课类型不能为空', trigger: 'change'
                            }}},[_c('el-select',{staticClass:"full-width",attrs:{"placeholder":"请选择","clearable":""},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}},[_c('el-option',{attrs:{"label":"全日制","value":"全日制"}}),_c('el-option',{attrs:{"label":"兼读制","value":"兼读制"}}),_c('el-option',{attrs:{"label":"遥距课程","value":"遥距课程"}})],1)],1)],1),_c('div',{staticClass:"one-input"},[_c('el-tooltip',{attrs:{"content":"国外大学请填写学位证上的英文名称","placement":"top"}},[_c('img',{staticClass:"sigh-img",staticStyle:{"left":"42px","bottom":"28px"},attrs:{"src":require("@/assets/img/hover.png"),"alt":""}})]),_c('el-form-item',{attrs:{"label":"专业：","prop":'educationList.' + index + '.professional',"rules":{
                                required: true, message: '专业不能为空', trigger: 'blur'
                            }}},[_c('el-input',{staticClass:"full-width",attrs:{"placeholder":"请输入主修专业","maxlength":"50"},model:{value:(item.professional),callback:function ($$v) {_vm.$set(item, "professional", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.professional"}})],1)],1),_c('div',{staticClass:"city-studied"},[_c('el-form-item',{staticStyle:{"width":"300px"},attrs:{"label":"修读城市：","prop":'educationList.' + index + '.schoolAddressAboard',"rules":{
                                required: true, message: '修读城市不能为空', trigger: 'change'
                            }}},[_c('el-radio-group',{on:{"change":function($event){return _vm.changeSchool($event,item)}},model:{value:(item.schoolAddressAboard),callback:function ($$v) {_vm.$set(item, "schoolAddressAboard", $$v)},expression:"item.schoolAddressAboard"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("国内")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("国外")])],1)],1),(item.schoolAddressAboard === 0)?_c('el-form-item',{key:1,staticStyle:{"margin-left":"0px","width":"195px"},attrs:{"prop":'educationList.' + index + '.schoolAddress.area',"rules":[{
                                required: true, message: '修读城市不能为空', trigger: 'change'
                            }]}},[_c('CitySelect',{staticStyle:{"width":"200px","margin-left":"-130px"},model:{value:(item.schoolAddress.area),callback:function ($$v) {_vm.$set(item.schoolAddress, "area", $$v)},expression:"item.schoolAddress.area"}})],1):_vm._e(),(item.schoolAddressAboard === 1)?_c('el-form-item',{key:2,staticStyle:{"margin-left":"0px","width":"195px"},attrs:{"prop":'educationList.' + index + '.schoolAddress.foreign',"rules":[{
                                required: true, message: '修读城市不能为空', trigger: 'blur'
                            }]}},[_c('el-input',{staticStyle:{"width":"200px","margin-left":"-130px"},attrs:{"maxlength":"100","placeholder":"请输入"},model:{value:(item.schoolAddress.foreign),callback:function ($$v) {_vm.$set(item.schoolAddress, "foreign", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.schoolAddress.foreign"}})],1):_vm._e(),_c('span',{staticClass:"tips"},[_vm._v("(上课地点)")])],1)]),_c('el-col',{attrs:{"span":12}},[_c('div',[_c('el-form-item',{attrs:{"label":"入学年月：","prop":'educationList.' + index + '.admissionTime',"rules":{
                                required: true, validator: _vm.checkAdmissionTimeLine, trigger: 'change'
                            }}},[_c('el-date-picker',{staticClass:"full-width",attrs:{"value-format":"yyyy-MM","type":"month","picker-options":_vm.pickerOptions,"format":"yyyy-MM","placeholder":"请选择"},on:{"change":function (value) { return _vm.pikerStartTime(value, index); }},model:{value:(item.admissionTime),callback:function ($$v) {_vm.$set(item, "admissionTime", $$v)},expression:"item.admissionTime"}})],1)],1),_c('div',[_c('el-form-item',{attrs:{"label":"毕业年月：","prop":'educationList.' + index + '.graduateTime',"rules":{
                                required: true, validator: _vm.checkGraduateTimeLine, trigger: 'change'
                            }}},[_c('el-date-picker',{staticClass:"full-width",attrs:{"value-format":"yyyy-MM","type":"month","picker-options":_vm.pickerOptions,"format":"yyyy-MM","placeholder":"请选择"},on:{"change":function (value) { return _vm.pikerEndTime(value, index); }},model:{value:(item.graduateTime),callback:function ($$v) {_vm.$set(item, "graduateTime", $$v)},expression:"item.graduateTime"}})],1)],1),_c('div',{staticClass:"one-input"},[_c('el-tooltip',{attrs:{"content":"必须有学位证书","placement":"top"}},[_c('img',{staticClass:"sigh-img",staticStyle:{"left":"42px","bottom":"28px"},attrs:{"src":require("@/assets/img/hover.png"),"alt":""}})]),_c('el-form-item',{attrs:{"label":"学位：","prop":'educationList.' + index + '.background',"rules":{
                                required: true, message: '学位不能为空', trigger: 'change'
                            }}},[_c('el-select',{staticClass:"full-width",attrs:{"placeholder":"请选择","clearable":""},model:{value:(item.background),callback:function ($$v) {_vm.$set(item, "background", $$v)},expression:"item.background"}},[_c('el-option',{attrs:{"label":"无学位（专科）","value":"无学位（专科）"}}),_c('el-option',{attrs:{"label":"无学位（本科）","value":"无学位（本科）"}}),_c('el-option',{attrs:{"label":"无学位（硕士）","value":"无学位（硕士）"}}),_c('el-option',{attrs:{"label":"学士学位","value":"学士学位"}}),_c('el-option',{attrs:{"label":"硕士学位","value":"硕士学位"}}),_c('el-option',{attrs:{"label":"博士学位","value":"博士学位"}})],1)],1)],1)])],1)],1)}),_c('div',{staticClass:"add-people",on:{"click":_vm.educaAdd}},[_vm._v("+新增学历信息")])],2),_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card-heard"},[_vm._v("主申请人专业资格及会员资格（如有）")]),_vm._l((_vm.userForm.qualificationList),function(item,index){return _c('div',{key:item.id,staticClass:"sister-box"},[_c('div',{staticClass:"sister-heard"},[_c('div'),_c('div',{staticClass:"del",on:{"click":function($event){return _vm.qualiDel(index)}}},[_vm._v("删除")])]),_c('el-row',{staticClass:"block-top"},[_c('el-col',{attrs:{"span":12}},[_c('div',[_c('el-form-item',{attrs:{"label":"颁授机构全名：","prop":'qualificationList.' + index + '.college',"rules":{
                                required: true, message: '颁授机构全名不能为空', trigger: 'blur'
                            }}},[_c('el-input',{staticClass:"full-width",attrs:{"maxlength":"50","placeholder":"颁授机构全名"},model:{value:(item.college),callback:function ($$v) {_vm.$set(item, "college", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.college"}})],1)],1),_c('div',[_c('el-form-item',{attrs:{"label":"资格证书名称：","prop":'qualificationList.' + index + '.qualification',"rules":{
                                required: true, message: '资格证书名称不能为空', trigger: 'blur'
                            }}},[_c('el-input',{staticClass:"full-width",attrs:{"maxlength":"50","placeholder":"请输入资格证书名称全称"},model:{value:(item.qualification),callback:function ($$v) {_vm.$set(item, "qualification", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.qualification"}})],1)],1)]),_c('el-col',{attrs:{"span":12}},[_c('div',[_c('el-form-item',{attrs:{"label":"颁授年份：","prop":'qualificationList.' + index + '.promulgation',"rules":{
                                required: true, message: '颁授年份不能为空', trigger: 'change'
                            }}},[_c('el-date-picker',{staticClass:"full-width",attrs:{"picker-options":_vm.pickerOptions,"type":"year","format":"yyyy","value-format":"yyyy","placeholder":"请选择"},model:{value:(item.promulgation),callback:function ($$v) {_vm.$set(item, "promulgation", $$v)},expression:"item.promulgation"}})],1)],1),_c('div',{staticClass:"city-studied"},[_c('el-form-item',{staticStyle:{"width":"300px"},attrs:{"label":"颁授城市：","prop":'qualificationList.' + index + '.collegeAddressAboard',"rules":{
                                required: true, message: '颁授城市不能为空', trigger: 'change'
                            }}},[_c('el-radio-group',{on:{"change":function($event){return _vm.changeCity($event,item)}},model:{value:(item.collegeAddressAboard),callback:function ($$v) {_vm.$set(item, "collegeAddressAboard", $$v)},expression:"item.collegeAddressAboard"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("国内")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("国外")])],1)],1),(item.collegeAddressAboard === 0)?_c('el-form-item',{key:3,staticStyle:{"margin-left":"0px","width":"195px"},attrs:{"prop":'qualificationList.' + index + '.collegeAddress.area',"rules":[{
                                required: true, message: '颁授城市不能为空', trigger: 'change'
                            }]}},[_c('CitySelect',{staticStyle:{"width":"200px","margin-left":"-130px"},model:{value:(item.collegeAddress.area),callback:function ($$v) {_vm.$set(item.collegeAddress, "area", $$v)},expression:"item.collegeAddress.area"}})],1):_vm._e(),(item.collegeAddressAboard === 1)?_c('el-form-item',{key:4,staticStyle:{"margin-left":"0px","width":"195px"},attrs:{"prop":'qualificationList.' + index + '.collegeAddress.foreign',"rules":[{
                                required: true, message: '颁授城市不能为空', trigger: 'blur'
                            }]}},[_c('el-input',{staticStyle:{"width":"200px","margin-left":"-130px"},attrs:{"maxlength":"100","placeholder":"请输入"},model:{value:(item.collegeAddress.foreign),callback:function ($$v) {_vm.$set(item.collegeAddress, "foreign", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.collegeAddress.foreign"}})],1):_vm._e()],1)])],1)],1)}),_c('div',{staticClass:"add-people",on:{"click":_vm.qualiAdd}},[_vm._v("+新增资格证书")])],2)]),_c('div',{staticClass:"operations-group"},[_c('el-button',{staticClass:"person-btn",on:{"click":function($event){return _vm.saveForm('last')}}},[_vm._v("上一步")]),((_vm.user.baseSchedule - 1) < 4)?_c('el-button',{staticClass:"person-btn btn-blue",attrs:{"loading":_vm.isSave},on:{"click":function($event){return _vm.saveForm('save')}}},[_vm._v("保存")]):_vm._e(),_c('el-button',{staticClass:"person-btn btn-blue",attrs:{"loading":_vm.isNext},on:{"click":function($event){return _vm.saveForm('next')}}},[_vm._v("下一步")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }