<!--
 * @Author: zhai
 * @Description: 学历/专业资格
-->
<template>
    <div class="qualification">
        <el-form ref="userForm" :model="userForm" label-width="130px" label-position="rigth">
            <!-- 主申请人学历 -->
            <div class="card-container">
                <div class="card-heard">主申请人学历</div>
                <div v-for="(item, index) in userForm.educationList" :key="index" class="sister-box">
                    <div v-if="index != 0" class="sister-heard">
                        <div />
                        <div class="del" @click="educaDel(index)">删除</div>
                    </div>
                    <el-row class="block-top">
                        <el-col :span="12">
                            <div class="one-input">
                                <el-tooltip content="国外大学请填写学位证上的英文名称" placement="top">
                                    <img class="sigh-img" style="left: 15px;bottom: 28px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="颁授机构：" :prop="'educationList.' + index + '.college'" :rules="{
                                    required: true, message: '颁授机构不能为空', trigger: 'change'
                                }"
                                >
                                    <el-autocomplete v-model="item.college" class="full-width" :fetch-suggestions="querySearchAsync" placeholder="颁授机构" />
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="授课类型：" :prop="'educationList.' + index + '.type'" :rules="{
                                    required: true, message: '授课类型不能为空', trigger: 'change'
                                }"
                                >
                                    <el-select v-model="item.type" class="full-width" placeholder="请选择" clearable>
                                        <el-option label="全日制" value="全日制" />
                                        <el-option label="兼读制" value="兼读制" />
                                        <el-option label="遥距课程" value="遥距课程" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-tooltip content="国外大学请填写学位证上的英文名称" placement="top">
                                    <img class="sigh-img" style="left: 42px;bottom: 28px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="专业：" :prop="'educationList.' + index + '.professional'" :rules="{
                                    required: true, message: '专业不能为空', trigger: 'blur'
                                }"
                                >
                                    <el-input v-model.trim="item.professional" class="full-width" placeholder="请输入主修专业" maxlength="50" />
                                </el-form-item>
                            </div>
                            <div class="city-studied">
                                <el-form-item label="修读城市：" :prop="'educationList.' + index + '.schoolAddressAboard'" :rules="{
                                    required: true, message: '修读城市不能为空', trigger: 'change'
                                }" style="width: 300px;"
                                >
                                    <el-radio-group v-model="item.schoolAddressAboard" @change="changeSchool($event,item)">
                                        <el-radio :label="0">国内</el-radio>
                                        <el-radio :label="1">国外</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item v-if="item.schoolAddressAboard === 0" :key="1" :prop="'educationList.' + index + '.schoolAddress.area'" :rules="[{
                                    required: true, message: '修读城市不能为空', trigger: 'change'
                                }]" style="margin-left: 0px; width: 195px;"
                                >
                                    <CitySelect v-model="item.schoolAddress.area" style="width: 200px; margin-left: -130px;" />
                                </el-form-item>
                                <el-form-item v-if="item.schoolAddressAboard === 1" :key="2" :prop="'educationList.' + index + '.schoolAddress.foreign'" :rules="[{
                                    required: true, message: '修读城市不能为空', trigger: 'blur'
                                }]" style="margin-left: 0px; width: 195px;"
                                >
                                    <el-input v-model.trim="item.schoolAddress.foreign" style="width:200px; margin-left: -130px;" maxlength="100" placeholder="请输入" />
                                </el-form-item>
                                <span class="tips">(上课地点)</span>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div>
                                <el-form-item label="入学年月：" :prop="'educationList.' + index + '.admissionTime'" :rules="{
                                    required: true, validator: checkAdmissionTimeLine, trigger: 'change'
                                }"
                                >
                                    <el-date-picker v-model="item.admissionTime" value-format="yyyy-MM" type="month" :picker-options="pickerOptions" format="yyyy-MM" class="full-width" placeholder="请选择" @change="(value) => pikerStartTime(value, index)" />
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="毕业年月：" :prop="'educationList.' + index + '.graduateTime'" :rules="{
                                    required: true, validator: checkGraduateTimeLine, trigger: 'change'
                                }"
                                >
                                    <el-date-picker v-model="item.graduateTime" value-format="yyyy-MM" type="month" :picker-options="pickerOptions" format="yyyy-MM" class="full-width" placeholder="请选择" @change="(value) => pikerEndTime(value, index)" />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-tooltip content="必须有学位证书" placement="top">
                                    <img class="sigh-img" style="left: 42px;bottom: 28px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="学位：" :prop="'educationList.' + index + '.background'" :rules="{
                                    required: true, message: '学位不能为空', trigger: 'change'
                                }"
                                >
                                    <el-select v-model="item.background" class="full-width" placeholder="请选择" clearable>
                                        <el-option label="无学位（专科）" value="无学位（专科）" />
                                        <el-option label="无学位（本科）" value="无学位（本科）" />
                                        <el-option label="无学位（硕士）" value="无学位（硕士）" />
                                        <el-option label="学士学位" value="学士学位" />
                                        <el-option label="硕士学位" value="硕士学位" />
                                        <el-option label="博士学位" value="博士学位" />
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="add-people" @click="educaAdd">+新增学历信息</div>
            </div>
            <!-- 主申请人专业资格及会员资格（如有） -->
            <div class="card-container">
                <div class="card-heard">主申请人专业资格及会员资格（如有）</div>
                <div v-for="(item, index) in userForm.qualificationList" :key="item.id" class="sister-box">
                    <div class="sister-heard">
                        <div />
                        <div class="del" @click="qualiDel(index)">删除</div>
                    </div>
                    <el-row class="block-top">
                        <el-col :span="12">
                            <div>
                                <el-form-item label="颁授机构全名：" :prop="'qualificationList.' + index + '.college'" :rules="{
                                    required: true, message: '颁授机构全名不能为空', trigger: 'blur'
                                }"
                                >
                                    <el-input v-model.trim="item.college" maxlength="50" class="full-width" placeholder="颁授机构全名" />
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="资格证书名称：" :prop="'qualificationList.' + index + '.qualification'" :rules="{
                                    required: true, message: '资格证书名称不能为空', trigger: 'blur'
                                }"
                                >
                                    <el-input v-model.trim="item.qualification" maxlength="50" class="full-width" placeholder="请输入资格证书名称全称" />
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div>
                                <el-form-item label="颁授年份：" :prop="'qualificationList.' + index + '.promulgation'" :rules="{
                                    required: true, message: '颁授年份不能为空', trigger: 'change'
                                }"
                                >
                                    <el-date-picker v-model="item.promulgation" :picker-options="pickerOptions" type="year" format="yyyy" value-format="yyyy" class="full-width" placeholder="请选择" />
                                </el-form-item>
                            </div>
                            <div class="city-studied">
                                <el-form-item label="颁授城市：" :prop="'qualificationList.' + index + '.collegeAddressAboard'" :rules="{
                                    required: true, message: '颁授城市不能为空', trigger: 'change'
                                }" style="width: 300px;"
                                >
                                    <el-radio-group v-model="item.collegeAddressAboard" @change="changeCity($event,item)">
                                        <el-radio :label="0">国内</el-radio>
                                        <el-radio :label="1">国外</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item v-if="item.collegeAddressAboard === 0" :key="3" :prop="'qualificationList.' + index + '.collegeAddress.area'" :rules="[{
                                    required: true, message: '颁授城市不能为空', trigger: 'change'
                                }]" style="margin-left: 0px; width: 195px;"
                                >
                                    <CitySelect v-model="item.collegeAddress.area" style="width: 200px; margin-left: -130px;" />
                                </el-form-item>
                                <el-form-item v-if="item.collegeAddressAboard === 1" :key="4" :prop="'qualificationList.' + index + '.collegeAddress.foreign'" :rules="[{
                                    required: true, message: '颁授城市不能为空', trigger: 'blur'
                                }]" style="margin-left: 0px; width: 195px;"
                                >
                                    <el-input v-model.trim="item.collegeAddress.foreign" style="width:200px; margin-left: -130px;" maxlength="100" placeholder="请输入" />
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="add-people" @click="qualiAdd">+新增资格证书</div>
            </div>
        </el-form>
        <!-- 保存按钮 -->
        <div class="operations-group">
            <el-button class="person-btn" @click="saveForm('last')">上一步</el-button>
            <el-button v-if="(user.baseSchedule - 1) < 4" class="person-btn btn-blue" :loading="isSave" @click="saveForm('save')">保存</el-button>
            <el-button class="person-btn btn-blue" :loading="isNext" @click="saveForm('next')">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import CitySelect from "@/components/CitySelect/CitySelect";
import { saveQualiAndEduca, schoolList } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
export default {
    name: "Qualification",
    components: {
        CitySelect,
    },
    data() {
        // 校验入学年月
        let checkAdmissionTime = (rule, value, callback) => {
            if (value === "" || value === null) {
                callback(new Error("入学年月不得在毕业年月之后或同月"));
            } else {
                callback();
            }
        };
        // 校验毕业年月
        let checkGraduateTime = (rule, value, callback) => {
            if (value === "" || value === null) {
                callback(new Error("毕业年月不得在入学年月之前或同月"));
            } else {
                callback();
            }
        };
        return {
            userForm: {
                option: "",
                educationList: [
                    {
                        orderId: this.$route.params.id,
                        college: "",
                        type: "",
                        admissionTime: "",
                        graduateTime: "",
                        background: "",
                        professional: "",
                        schoolAddress: {
                            area: ["", ""],
                            foreign: "",
                        },
                        schoolAddressAboard: 0,
                        remark: "",
                    },
                ],
                qualificationList: [
                    // {
                    //     "orderId": this.$route.params.id,
                    //     "college": "",
                    //     "collegeAddress": {
                    //         "area": [
                    //         ],
                    //         "foreign": ""
                    //     },
                    //     "collegeAddressAboard": "1",
                    //     "promulgation": "",
                    //     "qualification": "",
                    //     "remark": ""
                    // }
                ],
                order: {
                    qualification: 0,
                    graduate: 0,
                },
                spouseHasDegree: false,
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6;
                },
            },
            rules: {
                birthday: [
                    {
                        required: true,
                        message: "请输入出生日期",
                        trigger: "blur",
                    },
                ],
            },
            isSave: false, //防抖loading
            isNext: false,
            checkAdmissionTimeLine: checkAdmissionTime, // 校验入学年月
            checkGraduateTimeLine: checkGraduateTime, // 校验毕业年月
        };
    },
    computed: {
        ...mapState(["user"])
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 数据回显
        dataEcho() {
            this.getInfoData(this.$route.params.id).then((res) => {
                if (res.data.educationList.length !== 0) {
                    this.userForm.educationList = res.data.educationList;
                }
                if (res.data.qualificationList.length !== 0) {
                    this.userForm.qualificationList =
                        res.data.qualificationList;
                }
                if (res.data.order) {
                    this.userForm.order = res.data.order;
                }
                // 配偶是否有学历
                for (let i = 0; i < res.data.subsetList.length; i++) {
                    if (
                        res.data.subsetList[i].relation === "3" &&
                        res.data.subsetList[i].hasDegree !== 0
                    ) {
                        this.userForm.spouseHasDegree = true;
                    }
                }
            });
        },
        //保存表单
        saveForm(parmas) {
            localStorage.setItem("stepChange", 0);
            this.$store.commit("stepChange");
            if (parmas === "save") {
                this.isSave = true;
                this.userForm.option = "save";
                return this.hanledSaveQuestion(3);
            } else if (parmas === "last") {
                this.$router.replace({
                    name: "ptep" + 2,
                });
            } else {
                this.userForm.option = "submit";
                this.$refs["userForm"].validate((valid) => {
                    if (valid) {
                        let noPass = false; //不通過
                        for (let val of this.userForm.educationList) {
                            // 校验出生城市国内
                            if (
                                val.schoolAddressAboard === 0 &&
                                val.schoolAddress.area[0] === ""
                            ) {
                                this.$message.error("请完善修读城市");
                                noPass = true;
                                return false;
                            }
                        }
                        if (!noPass) {
                            this.isNext = true;
                            return this.hanledSaveQuestion(4);
                        }
                    } else {
                        return this.$message.error("请完善信息");
                    }
                });
            }
        },
          // 发起保存动作
        hanledSaveQuestion(parmas) {
            return new Promise((resolve, reject) => {
                saveQualiAndEduca(this.userForm).then((res) => {
                    this.isNext = false;
                    this.isSave = false;
                    if (res.code == 200) {
                        // 切换步骤保存 下一步的时候才设置
                        if (this.userForm.option === "submit") {
                            this.saveUserData(parmas);
                            this.$message.success("提交成功！");
                            this.$router.replace({
                                name: "ptep" + parmas,
                            });
                        } else {
                            this.$message.success("保存成功！");
                            this.dataEcho();
                        }
                        resolve(res);
                    } else {
                        reject(res);
                    }
                });
            });
        },
        educaDel(index) {
            this.userForm.educationList.splice(index, 1);
        },
        educaAdd() {
            this.userForm.educationList.push({
                orderId: this.$route.params.id,
                college: "",
                type: "",
                admissionTime: "",
                graduateTime: "",
                background: "",
                professional: "",
                schoolAddress: {
                    area: [],
                    foreign: "",
                },
                schoolAddressAboard: 0,
                remark: "",
            });
        },
        qualiDel(index) {
            this.userForm.qualificationList.splice(index, 1);
        },
        qualiAdd() {
            this.userForm.qualificationList.push({
                orderId: this.$route.params.id,
                college: "",
                collegeAddress: {
                    area: [],
                    foreign: "",
                },
                collegeAddressAboard: 0,
                promulgation: "",
                qualification: "",
                remark: "",
            });
        },
        // 颁发机构搜索
        querySearchAsync(queryString, cb) {
            schoolList({
                name: queryString,
                productId:1
            }).then((res) => {
                if (res.code === 200) {
                    var restaurants = res.data;
                    var results = queryString
                        ? restaurants.filter(
                              this.createStateFilter(queryString)
                          )
                        : restaurants;
                    cb(results);
                }
            });
            // var restaurants = this.restaurants;
            // var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
            // clearTimeout(this.timeout);
            // this.timeout = setTimeout(() => {
            //     cb(results);
            // }, 3000 * Math.random());
        },
        createStateFilter(queryString) {
            return (state) => {
                return (
                    state.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        pikerStartTime(e, idx) {
            let admissionTime = e === null ? "" : e;
            let graduateTime = this.userForm.educationList[idx].graduateTime;
            if (graduateTime !== "" && graduateTime !== null) {
                // let result = Date.parse(graduateTime.trim().replace(/-/g, '-')) > Date.parse(admissionTime.trim().replace(/-/g, '-'));
                let result =
                    new Date(graduateTime).getTime() >
                    new Date(admissionTime).getTime();
                if (!result) {
                    this.userForm.educationList[idx].admissionTime = "";
                }
            }
        },
        pikerEndTime(e, idx) {
            let graduateTime = e === null ? "" : e;
            let admissionTime = this.userForm.educationList[idx].admissionTime;
            if (admissionTime !== "" && admissionTime !== null) {
                // let result = Date.parse(admissionTime.trim().replace(/-/g, '/')) < Date.parse(graduateTime.trim().replace(/-/g, '/'));
                let result =
                    new Date(admissionTime).getTime() <
                    new Date(graduateTime).getTime();
                if (!result) {
                    this.userForm.educationList[idx].graduateTime = "";
                }
            }
        },
        // 更改修读城市
        changeSchool(e, item) {
            if (e === 0) {
                item.schoolAddress.foreign = "";
            } else {
                item.schoolAddress.area = [];
            }
        },
        // 颁授城市
        changeCity(e, item) {
            if (e === 0) {
                item.collegeAddress.foreign = "";
            } else {
                item.collegeAddress.area = [];
            }
        }
    },
};
</script>

<style lang="less" scoped>
.qualification {
    .card-container {
        box-sizing: border-box;
    }

    .sister-box {
        .sister-heard {
            border-bottom: 1px solid #dcdfe6;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0 16px;
            color: #1890ff;
            height: 36px;
        }
    }

    .block-top {
        margin: 16px 16px 0 16px;
        border-bottom: 1px solid #dcdfe6;
        box-sizing: border-box;
    }

    .add-people {
        width: 928px;
        height: 35px;
        line-height: 35px;
        border: 1px solid #dcdfe6;
        color: #409eff;
        font-size: 14px;
        text-align: center;
        margin: 16px auto;
        cursor: pointer;
    }

    .sigh-img {
        position: absolute;
    }

    .full-width {
        width: 338px;
    }

    .city-studied {
        position: relative;
        display: flex;

        .tips {
            position: absolute;
            top: 27px;
            left: 48px;
            color: #606266;
        }
    }

    .auxiliary-box {
        padding: 16px 16px 0;

        p {
            font-size: 14px;
            color: #303133;
            margin-bottom: 6px;
        }
    }

    .one-input {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }

    .del {
        cursor: pointer;
    }

    .auxiliary-tit {
        &::before {
            content: "*";
            color: #f56c6c;
            margin-right: 4px;
        }
    }
}

/deep/.el-radio {
    margin-right: 10px;
}

.city-studied /deep/.el-form-item__error {
    width: 120px;
    margin-left: -130px;
}
</style>
